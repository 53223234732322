/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
// @ts-ignore
import { eventIdFbKey } from '@libs/client/helpers'
import { _listener } from '@libs/client/hooks'
import { getCookie } from '@ui/helpers/getCookie'
import { AxiosInstance, AxiosResponse } from 'axios'
export interface IRequestOptions {
  headers?: any
  baseURL?: string
  responseType?: string
}

export interface IRequestConfig {
  method?: any
  headers?: any
  url?: any
  data?: any
  params?: any
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    const slug = process.browser ? window.location.pathname.split('/')[1] : ''
    const pCookie =
      slug && slug !== 'checkout' ? getCookie(`pversion_${slug}`) : getCookie('pversion') || ''

    return serviceOptions.axios
      .request({
        ...configs,
        headers: {
          ...configs.headers,
          pVersion: `${pCookie}`,
          pinactive: `${!!_listener?.stateStorer?.get('store')?.pages?.pinactive}`,
          pvinactive: `${!!_listener?.stateStorer?.get('store')?.pages?.pvinactive}`,
          'event-id': _listener?.stateStorer?.get(eventIdFbKey) || ''
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}
export function axiosAbd(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url }
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  }
  return configs
}

const basePath = ''

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[]
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[]
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number
  items?: T[]
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number
  items?: T[]
}

// customer definition
// empty

export class CartLegacyService {
  /**
   * Create new Cart, or add 1st item to cart with an optional promotion code
   */
  static cart(
    params: {
      /**  */
      i?: string
      /**  */
      l?: string
      /** cart / checkout */
      f?: string
      /** stripe / paypalex */
      gw?: string
      /**  */
      lc?: number
      /** requestBody */
      body?: CreatingCart
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart'

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = {
        i: params['i'],
        l: params['l'],
        f: params['f'],
        gw: params['gw'],
        lc: params['lc']
      }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Calculate tax amount
   */
  static collectTax(
    params: {
      /** id */
      id: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: CollectTaxRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/collect-tax'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)
      configs.params = { f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Estimate tax info
   */
  static estimateTax(options: IRequestOptions = {}): Promise<EstimateTaxResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/estimate-tax'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static cart1(
    params: {
      /** id */
      id: string
      /**  */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** stripe / paypalex */
      gw?: string
      /**  */
      lc?: number
      /** requestBody */
      body?: UpdateCartRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)
      configs.params = { l: params['l'], f: params['f'], gw: params['gw'], lc: params['lc'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Update shipping info field
   */
  static shipping(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdatingCartShippingField
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/shipping'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static optOut(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdatingCartOptOut
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/opt-out'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Update billing info
   */
  static billing(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: BillingAddress
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/billing'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Apply the discount code to existing cart
   */
  static discount(
    params: {
      /**  */
      id: string
      /**  */
      code?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdatedCart> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/{id}/discount'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { code: params['code'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
 * Apply the abandoned code and get cart detail info (return full cart info)
FLOW code-generate: worker scan cart (NOT COMPLETED) and generate the code at ES (cms-abandon-code: id is code) - 3 steps (in SPECS), send to user with url: https://domain.com/a/[code]
FLOW code-apply:
- Browser call API to cms-order to load the Cart and override to localstorage
- Server log the abandon event to ES (cms-abandon-apply: cart_id, code, domain, ip, ua, rid, source - EMAIL/SMS/APP)
 */
  static abandoned(
    params: {
      /**  */
      code?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/abandoned'

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { code: params['code'] }
      let data = null

      configs.data = data
      axiosAbd(configs, resolve, reject)
    })
  }
  /**
 * Apply the offer code (return offer info)
link "https://{domain}/o/{code}"
 */
  static applyOffer(
    params: {
      /**  */
      code?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferApplyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/order/offer/apply'

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { code: params['code'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PayBraintreeService {
  /**
   * Get Braintree Client Token for paygate Id
   */
  static br(
    params: {
      /** Paygate Id */
      paygateId: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/br/{paygateId}'
      url = url.replace('{paygateId}', params['paygateId'] + '')

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Approve Braintree payment for the card-id
   */
  static br1(
    params: {
      /** CartID */
      cartId: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: PayBraintreeApproveRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/br/{cartId}'
      url = url.replace('{cartId}', params['cartId'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PayBraintreeLegacyService {
  /**
   * Process credit card payment for the card-id (card encryption)
   */
  static brEncrypt(
    params: {
      /** CartID */
      cartId: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: PayCreditcardEncryptRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/br-encrypt/{cartId}'
      url = url.replace('{cartId}', params['cartId'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PayCreditcardJsService {
  /**
   * Create Stripe.js PaymentIntent for the card-id
   */
  static cj(
    params: {
      /** CartID */
      id: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: PayCardJsCreateRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCardJsCreateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cj/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Approve Stripe.js credit card payment for the card-id
   */
  static cj1(
    params: {
      /** CartID */
      id: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: PayCardJsApproveRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cj/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PayCreditcardLegacyService {
  /**
   * Payment testing (ONLY available in DEV environment)
   */
  static cc(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Process credit card payment for the card-id
   */
  static cc1(
    params: {
      /** CartID */
      id: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: PayCreditcardRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Confirm credit card payment for the card-id
   */
  static confirm(
    params: {
      /** CartID */
      id: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: CreditcardConfirmRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc/{id}/confirm'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * Process credit card payment for the card-id (card encryption)
   */
  static ccEncrypt(
    params: {
      /** CartID */
      id: string
      /** Session ID */
      i?: string
      /** Location URL */
      l?: string
      /** requestBody */
      body?: PayCreditcardEncryptRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCreditcardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/cc-encrypt/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PayPaypalexLegacyService {
  /**
   * Payment testing (ONLY available in DEV environment)
   */
  static px(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options)

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * User create the payment
   */
  static create(
    params: {
      /** CartID */
      id: string
      /** sessionId */
      i?: string
      /** location */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexCreateRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/create'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * User approve / paid payment
   */
  static approve(
    params: {
      /** CartID */
      id: string
      /**  */
      i?: string
      /**  */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexApproveRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/approve'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * User cancel the payment
   */
  static cancel(
    params: {
      /** CartID */
      id: string
      /** sessionId */
      i?: string
      /** location */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexCancelRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/cancel'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PayPaypalexTwoStepService {
  /**
   * User create the payment
   */
  static create2(
    params: {
      /** CartID */
      id: string
      /** sessionId */
      i?: string
      /** location */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexCreate2Request
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/create2'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * User approve / paid payment
   */
  static review2(
    params: {
      /** CartID */
      id: string
      /**  */
      i?: string
      /**  */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexApproveRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/review2'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * User approve / paid payment
   */
  static approve2(
    params: {
      /** CartID */
      id: string
      /**  */
      i?: string
      /**  */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexApproveRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/approve2'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * User cancel the payment
   */
  static cancel2(
    params: {
      /** CartID */
      id: string
      /** sessionId */
      i?: string
      /** location */
      l?: string
      /** CART/CHECKOUT */
      f?: string
      /** requestBody */
      body?: PayPaypalexCancelRequest
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cart/pay/px/{id}/cancel2'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options)
      configs.params = { i: params['i'], l: params['l'], f: params['f'] }
      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export interface AbandonedCart {
  /**  */
  page_slug?: string

  /**  */
  items?: CartItem[]

  /**  */
  shipping?: CustomerAddress

  /**  */
  billing?: BillingAddress

  /**  */
  id?: string

  /**  */
  code?: string

  /**  */
  amount?: number

  /**  */
  items_amount?: number

  /**  */
  shipping_code?: string

  /**  */
  shipping_amount?: number

  /**  */
  shipping_value?: string

  /**  */
  discount_code?: string

  /**  */
  discount_value?: string

  /**  */
  discount_amount?: number

  /**  */
  tax_amount?: number

  /**  */
  tax_engine?: tax_engine
}

export interface BillingAddress {
  /**  */
  same_as_shipping?: boolean

  /**  */
  email?: string

  /**  */
  phone?: string

  /**  */
  last_name?: string

  /**  */
  first_name?: string

  /**  */
  address1?: string

  /**  */
  address2?: string

  /**  */
  postal_code?: string

  /**  */
  city?: string

  /**  */
  state?: string

  /**  */
  country?: string

  /**  */
  country_code?: string
}

export interface CartItem {
  /**  */
  id?: string

  /**  */
  variant_id?: string

  /**  */
  variant_data?: string

  /**  */
  quantity?: number
}

export interface CollectTaxRequest {
  /** No. 407 */
  address1?: string

  /** Lynshire Lane */
  address2?: string

  /** 45840 */
  postal_code?: string

  /** Findlay */
  city?: string

  /** Ohio */
  state?: string

  /** United States */
  country?: string

  /** US */
  country_code?: string

  /** CREDITCARD, PAYPALEX */
  method?: string
}

export interface CreatedCart {
  /**  */
  id?: string

  /**  */
  code?: string

  /**  */
  amount?: number

  /**  */
  items_amount?: number

  /**  */
  shipping_code?: string

  /**  */
  shipping_amount?: number

  /**  */
  shipping_value?: string

  /**  */
  discount_code?: string

  /**  */
  discount_value?: string

  /**  */
  discount_amount?: number

  /**  */
  handling_fee?: number

  /**  */
  tax_amount?: number

  /**  */
  tax_engine?: tax_engine

  /**  */
  items?: { [key: string]: number }
}

export interface CreatingCart {
  /**  */
  slug?: string

  /**  */
  page_id?: string

  /**  */
  page_version?: number

  /**  */
  source_referer?: string

  /**  */
  source_referer_data?: string

  /**  */
  discount_code?: string

  /**  */
  items?: CartItem[]
}

export interface CreditcardConfirmRequest {
  /**  */
  state?: string

  /**  */
  payment_intent_id?: string
}

export interface CustomerAddress {
  /**  */
  email?: string

  /**  */
  phone?: string

  /**  */
  last_name?: string

  /**  */
  first_name?: string

  /**  */
  address1?: string

  /**  */
  address2?: string

  /**  */
  postal_code?: string

  /**  */
  city?: string

  /**  */
  state?: string

  /**  */
  country?: string

  /**  */
  country_code?: string
}

export interface EstimateTaxResponse {
  /** 102.232.330.33 */
  client_ip?: string

  /**  */
  domain?: string

  /** Arizona */
  state?: string

  /** AZ */
  state_code?: string

  /** United States */
  country?: string

  /** US */
  country_code?: string

  /** 0.69 */
  tax_estimate_product_rate?: number

  /** 0.03 */
  tax_estimate_shipping_rate?: number
}

export interface OfferApplyResponse {
  /**  */
  issuccess?: boolean

  /**  */
  name?: string

  /**  */
  message?: string
}

export interface PayBraintreeApproveRequest {
  /**  */
  nonce?: string
}

export interface PayCardJsApproveRequest {
  /**  */
  signature?: string

  /**  */
  payment_intent_id?: string

  /**  */
  payment_method_id?: string
}

export interface PayCardJsCreateRequest {
  /**  */
  shipping?: CustomerAddress
}

export interface PayCardJsCreateResponse {
  /**  */
  signature?: string

  /**  */
  payment_intent_id?: string

  /**  */
  payment_intent_secret?: string
}

export interface PayCreditcardEncryptRequest {
  /**  */
  card_encrypt?: string

  /**  */
  shipping?: CustomerAddress
}

export interface PayPalTwoStepCreateRequest {
  /**  */
  shipping?: CustomerAddress
}

export interface PayCreditcardRequest {
  /**  */
  card_number?: string

  /**  */
  expiry_date?: string

  /**  */
  cvc?: string

  /**  */
  shipping?: CustomerAddress
}

export interface PayCreditcardResponse {
  descriptor?: string

  /**  */
  code?: string

  /**  */
  transaction_id?: string

  /**  */
  codes?: object

  /**  */
  card_first4?: string

  /**  */
  card_last4?: string
}

export interface PayPaypalexApproveRequest {
  /**  */
  order_id?: string
}

export interface PayPaypalexCancelRequest {
  /**  */
  order_id?: string
}

export interface PayPaypalexCreate2Request {
  /**  */
  return_url?: string

  /**  */
  flow?: string
}

export interface PayPaypalexCreateRequest {
  /**  */
  return_url?: string
}

export interface UpdateCartRequest {
  /**  */
  discount_code?: string

  /**  */
  page_id?: string

  /**  */
  page_version?: number

  /**  */
  cartItems?: CartItem[]
}

export interface UpdatedCart {
  /**  */
  amount?: number

  /**  */
  items_amount?: number

  /**  */
  shipping_code?: string

  /**  */
  shipping_amount?: number

  /**  */
  shipping_value?: string

  /**  */
  discount_code?: string

  /**  */
  discount_value?: string

  /**  */
  discount_amount?: number

  /**  */
  tax_amount?: number

  /**  */
  handling_fee?: number

  /**  */
  tax_engine?: tax_engine

  /**  */
  items?: { [key: string]: number }
}

export interface UpdatingCartItem {
  /**  */
  id?: string

  /**  */
  quantity?: number
}

export interface UpdatingCartOptOut {
  /**  */
  opt_out?: boolean
}

export interface UpdatingCartShippingField {
  /**  */
  field?: CustomerAddressField

  /**  */
  value?: string
}

export interface property_variant {
  /**  */
  name?: string

  /**  */
  value?: string
}

export enum CustomerAddressField {
  'email' = 'email',
  'phone' = 'phone',
  'last_name' = 'last_name',
  'first_name' = 'first_name',
  'address1' = 'address1',
  'address2' = 'address2',
  'postal_code' = 'postal_code',
  'city' = 'city',
  'state' = 'state',
  'country' = 'country',
  'country_code' = 'country_code'
}

export enum tax_engine {
  'DEFAULT' = 'DEFAULT',
  'AVATAX' = 'AVATAX',
  'IPSTACK' = 'IPSTACK'
}
